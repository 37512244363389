@import '../../assets/styles/variables';

.ant-modal{
  &-content{
    background-color: #3d4561 !important;
    background-image: url(../../assets/images/modal_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &-header{
    padding: 20px 22px;
    background-color: transparent !important;
    border: none !important;
    .ant-modal-title{
      color: #fff;
      font-size: 25px;
      font-weight: 600;
    }
  }
  &-body{
    padding: 22px !important;
    background-color: transparent !important;
    border-radius: 10px;
    font-size: 16px !important;
    color: #eef0f2 !important;
    font-weight: 500 !important;
    .form-box{
      width: 100% !important;
      min-width: initial !important;
      background-color: transparent !important;
      padding: 0 !important;
    }
  }
  &-btns{
    .ant-btn{
      &-primary{
        background-color: #4482ff;
        font-weight: 600;
      }
    }
  }
  &-footer{
    background-color: transparent !important;
    border: none !important;
    padding-right: 22px !important;
    padding-left: 22px !important;
    padding-bottom: 32px !important;

  }
  &-close{
    &-x{
      color: #fff;
    }
  }

  &-confirm{
    &-title{
      color: #fff !important;
      font-size: 25px !important;
      font-weight: 600 !important;
    }
    &-content{
      font-size: 16px !important;
      color: #eef0f2 !important;
      font-weight: 500 !important;
    }
    &-btns{
      .ant-btn{
        font-weight: 600;
        &-primary{
          background-color: #4482ff !important;
          border-color: #4482ff !important;
        }
      }
    }
  }


}


