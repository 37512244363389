@import '../../assets/styles/variables';
.dashboard-breadcrumb{
  .page-name-area{
    display: none !important;
  }
}
.dashboard{
  .sale-channel{
    width: 1105px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
    margin-bottom: 35px;
    border-bottom: 1px solid #3c4560 ;
    .left-side{
      display: flex;
      align-items: center;
      .sale-channel{
        &-title{
          position: relative;
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          margin-right: 16px;
          &::after{
            position: absolute;
            content: "";
            width: 100%;
            height: 4px;
            background-color: #037cf9;
            display: inline-block;
            left: 0;
            bottom: -16px;
          }
        }
        &-updated-at{
          font-size: 13px;
          color: #cdd9e9;
        }
      }
    }
    .right-side{
      .ant-select-selection--single{
        width: 137px;
        height: 40px !important;
        border-radius: 5px;
        border: solid 2px #262e47;
        background-color: #242c40;
        color: #cdd9e9;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .ant-select-selection__rendered{
          width: 137px;
          height: 27px;
          display: flex;
          align-items: center;
        }
        .ant-select-arrow-icon{
          color: #788195;
        }
        &:focus,
        &:active,
        &:hover {
          border-right-width: 2px !important;
        }
      }
    }
  }
  .turnover-info{
    text-align: right;
    &--title{
      font-size: 24px;
    }
    &--value{
      font-size: 24px;
      font-weight: bold;
    }
  
    @include responsive(on-mobile) {
      text-align: left;
      margin-top: 20px;
      &--title, &--value {
        font-size: 18px;
      }
    };
  }
  
  .box-container {
    width: 100%;
    display: flex;
    &--chart{
      margin-left: 10px;
      width: 645px;
      height: 460px;
      .chart-wrapper {
        background-color: #333b57;
        padding: 20px 30px;
      }
    }
    &--item {
      max-width: 355px;
      min-width: 355px;
      height: 100%;
      &:not(:last-child){
        margin-right: 20px;
      }
      @include responsive(on-mobile) {
        margin: 0px 0px 30px;
      };
      .card-head{
        &--title{
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #3c4560;
          padding-bottom: 10px;
          margin-bottom: 26px;

          .ant-select-selection--single{
            width: 137px;
            height: 27px !important;
            border-radius: 20px;
            background-color: #242c40;
            border: none;
            color: #cdd9e9;
            .ant-select-selection__rendered{
              width: 137px;
              height: 27px;
              display: flex;
              align-items: center;
            }
            .ant-select-arrow-icon{
              color: #000
            }
          }
          
          .card-name{
            position: relative;
            font-size: 20px;
            color: #fff;
            font-weight: 600;
            letter-spacing: 0.33px;
            &::after{
              position: absolute;
              content: "";
              width: 100%;
              height: 4px;
              background-color: #037cf9;
              display: inline-block;
              left: 0;
              bottom: -12px;
            }
          }
          .daterange-container{
            .ant-calendar-picker{
              width: 136px;
              margin-left: 3px;
              &-input{
                height: 27px;
                border-radius: 20px;
                background-color: #242c40;
                border: none;
                color: #cdd9e9;
              }
              &-icon{
                color: #4482ff;
              }
              &-clear{
                background-color: #242c40 ;
              }
            }
          }
        }
      }
      .box-item{
        background-color: #333b57;
        height: 310px;
        padding:30px;
        .card{
          &-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #535a6e;
            height: 49px;
            &:last-child{
              border: none;
            }
          }
          &--desc{
            font-size: 12px;
            font-weight: 600;
            color: #cdd9e9;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &--value{
            font-size: 19px;
            font-weight: 600;
            color: #7281b7;
          }
          &--title{
            color: #cdd9e9;
            font-size: 13px;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &-head{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #9b9b9b;
            font-size: 11px;
            font-weight: 600;  
            letter-spacing: 0.39px;        
          }
        }
      }
    }
  
    // .product-column{
    //   flex: 2 !important;
    // }
    // .count-column{
    //   text-align: center;
    // }
  }
  
  .channel-container{
    position: relative;
    .channel-select{
      position: absolute !important;
      right: 30px;
      top: 15px;
      min-width: 150px;
  
      @include responsive(on-mobile) {
        position: relative !important;
        width: 100%;
        top: 0px;
        right: 0px;
      };
    }
  }
  
  .date-filter-container{
    margin-bottom: 10px;
  }
  .isoBoxTitle{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #293245 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 0;
  }
  .isoBoxSubTitle {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .channel-card-box-wrapper{
    width: min-content;
    min-width: 450px;
    display: flex;
    flex-direction: column;
    .channel-card-head{
      width: min-content;
      min-width: 430px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #3c4560;
      margin-bottom: 26px;
      padding-bottom: 10px;
      .channel-name{
        position: relative;
        span{
          display: inline-block;
          width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &::after{
          position: absolute;
          content: "";
          width: 100px;
          height: 4px;
          background-color: #037cf9;
          display: inline-block;
          left: 0;
          bottom: -3px;
        }
      }
      .daterange-container{
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 4px;
          background-color: #037cf9;
          display: inline-block;
          left: 0;
          bottom: -12px;
        }
        .ant-calendar-picker{
          width: 136px;
          &:not(:first-child) {
            margin-left: 6px;
          }
          &-input{
            height: 40px;
            border-radius: 5px;
            border: solid 2px #262e47;
            background-color: #242c40;
            color: #cdd9e9;
          }
          &-icon{
            color: #4482ff;
          }
          &-clear{
            background-color: #242c40 ;
          }
        }
      }
    }
    .card-box{
      &--container{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      &--item{
        padding: 28px 0px 17px 0px;
        margin: 0px 20px 20px 0px;
        border-radius: 10px;
        background-color: #333b57;
        width: 205px;
        height: 100px;
        text-align: center;
        font-weight: 600;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        .success{
          color: #24b413;
        }
        .fail{
          color: #e65352;
        }
        &:last-child{
          margin-right: 0px;
        }
      }
      &--header{
        font-size: 11px;
        color: #9b9b9b;
      }
      &--content{
        font-size: 30px;
        color: #ffffff;
        line-height: 40px;
      }
    }
  }

  .dataWarning{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    span{
      padding-left:10px ;
      color: #788195;
    }
    .icon-warning_triangle{
      color: #788195;
    }
  }

  .chart-head{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #3c4560;
    padding-bottom: 20px;
    margin-bottom: 26px;
    .chart-name{
      position: relative;
      font-size: 20px;
      color: #fff;
      font-weight: 600;
      &::after{
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        background-color: #037cf9;
        display: inline-block;
        left: 0;
        bottom: -22px;
      }
    }
    .ant-calendar-picker{
        width: 136px;
        margin-left: 3px;
        &-input{
          height: 40px;
          border-radius: 5px;
          border: solid 2px #262e47;
          background-color: #242c40;
          color: #cdd9e9;
        }
        &-icon{
          color: #4482ff;
        }
        &-clear{
          background-color: #242c40 ;
        }
    }
  }
}