.permission-detail{
  .form-box{
    width: 100%;
  }
  .permission-group {
    margin: auto;
    display: flex;
    justify-content: center;
    &--form {
      width: 100%;
      display: flex;
      .form-button {
        top: 115px;
        right: 30px;
        position: absolute;
        .ant-btn.ant-btn-primary{
          padding-left: 58px;
          padding-right: 58px;
        }
      }
      .ant-form-item-children {
        .ant-input {
          height: 33px;
          cursor: text;
          color: #fff;
          font-size: 13px;
          text-align: left;
          line-height: 1.5;
          padding: 4px 10px;
          background-color: #fff;
          background-image: none;
          border: 1px solid #e9e9e9;
        }
      }
      .form-input{
        width: calc(50% - 30px);
        max-width: 428px;
      }
      .ant-form-item {
        margin-bottom: 15px;
        .ant-form-item-control-wrapper{
          padding-left: 0px;
        }
      }
    }
  }
}

.permission-detail-list {
  .box-primary.form-box {
    &:first-child {
      margin-right: 50px;
    }
    min-width: auto;
    width: calc(50% - 25px);
    .box-header{
      .right-side{
        .all-add-remove-button{
          background-color: #292b2c;
          color: #ffffff;
          font-size: 13px;
          font-weight: 600;
          padding: 2px 14px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .general-permission-group{
    border: solid 2px #293245;
    margin: 21px 0; 
    padding: 20px 25px;
    border-radius: 5px;
    border-top-left-radius: 10px;
    position: relative;
    &--name{
      position: absolute;
      top: -13px;
      font-size: 14px;
      font-weight: 600;
      padding: 0 7px;
      color: #788195;
      background-color: #333b57;
    }
    .permission-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #2b344c;
      border-radius: 5px;
      border: solid 2px #262e47;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 14px;
      color: #ffffff;
      margin: 10px 0;
      .icon{
        &-plus{
          color: #7ed321;
          font-size: 21px;
          cursor: pointer;
        }
        &-minus{
          color: #ff5163;
          font-size: 21px;
          cursor: pointer;
        }
      }
    }
  }
  .permission-detail-list-info{
    width: 100%;
    height: 151px;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      font-size: 14px;
      font-weight: 600;
      color: #788195;
    }
  }
}

.ant-modal-wrap{
  .permission-save-modal{
    .ant-modal-body{
      color: #eef0f2;
      font-size: 14px;
    }
  }
}
